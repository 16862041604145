import { render } from "react-dom";
import App from "./App";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import About from "./routes/about";
import Gallery from "./routes/gallery";
import Home from "./routes/home";
import BirdBattle from "./routes/birdbattle";
import ProfilePage from "./routes/profile";
import NotFound from "./routes/notfound";
import ProfileEmail from "./routes/profileEmail";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Routes>
            <Route path="*" element={<NotFound/>} />
            <Route path="/" element={ <App /> }>
                <Route exact path="home" element={ <Navigate to="/" /> }/>
                <Route path="" element={ <Home />} />
                <Route path="gallery">
                    <Route exact path="" element={ <Navigate to="1" /> }/>
                    <Route path=":pageNumber" element={ <Gallery /> }/>
                </Route>
                <Route path="about" element={ <About /> } />
                <Route path="birdbattle" element={<BirdBattle />} />
                <Route path="profile">
                    <Route exact path="" element={ <Navigate to="1" /> }/>
                    <Route path=":birds_id" element={ <ProfilePage /> }/>
                </Route>
                <Route path="emails">
                    <Route path=":email_id">
                        <Route path="profile" element={<ProfileEmail/>}  />
                    </Route>
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
    ,
    rootElement
);