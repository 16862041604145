import React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';


function LinkedLogo ({logoHeight}) {
    return (
        <Link href="/">
            <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="logo"
                style={{"height": logoHeight}}
            />
        </Link>
    )
}


function HeaderLink({ text, href, extraProps }) {
    return (
        <Link
            href={href}
            color="inherit"
            underline="none"
            sx={{flexGrow: 1, margin: "5%" }}
            variant="h6"
            {...extraProps}
        >
            {text}
        </Link>
    )
}

const MobileHeader = () => {
    const pages = ["Home", "Gallery", "BirdBattle", "About"]
    const logoHeight = document.documentElement.clientHeight * 0.05
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" color="transparent">
            <Toolbar disableGutters>
                <LinkedLogo logoHeight={logoHeight} />
                <Box sx={{ justifyContent: "flex-end", flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: 'block', md: 'none' },
                      }}
                    >
                        {
                            pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Link
                                        textAlign="center"
                                        href={`/${page}`}
                                        color="inherit"
                                        underline="none"
                                    >
                                        {page}
                                    </Link>
                                </MenuItem>
                                )
                            )
                        }
                    </Menu>
                </Box>
        </Toolbar>
    </AppBar>
  );
};


function DesktopHeader() {
    const logoHeight = document.documentElement.clientHeight * 0.05
    return (
        <AppBar position="static" color="transparent">
            <Toolbar >
                <LinkedLogo logoHeight={logoHeight} />
                <Box sx={{ marginLeft: "70%"}}>
                    <HeaderLink href="/" text="Home" />
                    <HeaderLink href="/gallery" text="Gallery" />
                    <HeaderLink href="/birdbattle" text="BirdBattle" />
                    <HeaderLink href="/about" text="About" />
                </Box>
            </Toolbar>
        </AppBar>
    )
}


function Header() {
    const desktop = document.documentElement.clientWidth > 900

    return desktop ? DesktopHeader() : MobileHeader()
}

export default Header;