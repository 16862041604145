import { Header } from "./components/common";
import {Outlet} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <div className="App">
          <Header />
          <Outlet />
      </div>
  );
}

export default App;
