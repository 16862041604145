import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../index.css";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import CustomModal from "../components/common/modal";
import StandardImageList from "../components/common/gallery";
import Link from '@mui/material/Link';
import * as qs from 'qs'
import {FilterSet, FilterStates} from "../components/common/filter";

const BASEURL = process.env.REACT_APP_BASEURL

function Paginate(currentPage, setCurrentPage, setClickedItemId, filterStates) {

    const [pageCount, setPageCount] = useState(1)
    const navigate = useNavigate()
    const filters = getFiltersFromStates(filterStates)
    const string_filters = JSON.stringify(filters)

    useEffect(
        () => axios.get(
            `${BASEURL}/galleryPages`,
            {
                    params: JSON.parse(string_filters),
                    paramsSerializer: params => qs.stringify(params,{arrayFormat: 'repeat'})
                }
        ).then(res => setPageCount(res.data)),
        [string_filters]
    )

    function handleGalleryPageUpdate (event) {
        setClickedItemId(null)
        setCurrentPage(event.selected + 1)
        navigate(`/gallery/${event.selected + 1}`)
    }

    return (
        <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            breakLabel="..."
            previousLabel="<"
            nextLabel=">"
            onPageChange={(event) => handleGalleryPageUpdate(event)}
            renderOnZeroPageCount={null}
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            key="paginate"
            forcePage={currentPage-1}
        />
    )
}


function CreateModal ( clickedItemId, setClickedItemId, modalImage ) {
    const modalContent = (
        <Link href={`/profile/${modalImage.id}`} >
            <img
                src={modalImage.url}
                alt={modalImage.alt}
                key={modalImage.id}
                className="enlarged-photo"
            />
        </Link>
    )
    const headingText = (
        <Link href={`/profile/${modalImage.id}`} underline="none" color="inherit">{modalImage.alt}</Link>
    )
    return (
        <CustomModal
            isOpen={modalImage.id === clickedItemId}
            setIsOpen={setClickedItemId}
            headingText={headingText}
            modalContent={modalContent}
        />
    )
}


function GalleryImages (
    currentPage, setCurrentPage, clickedItemId, setClickedItemId, filterStates
) {

    const [displayImages, setDisplayImages] = useState([])
    const [hoveredItemId, setHoveredItemId] = useState(null)
    const filters = getFiltersFromStates(filterStates)
    const stringFilters = JSON.stringify(filters)

    useEffect(
        () => {
            axios.get(
                `${BASEURL}/gallery/${currentPage}`,
                {
                    params: JSON.parse(stringFilters),
                    paramsSerializer: params => qs.stringify(params,{arrayFormat: 'repeat'})
                }
            )
            .then(res => {
                setDisplayImages(res.data)
            })
        },
        [currentPage, stringFilters]
    )

    const gallery = (
        <StandardImageList
            listItemsArr={displayImages}
            setClickedItemId={setClickedItemId}
            hoveredItemId={hoveredItemId}
            setHoveredItemId={setHoveredItemId}
        />
    )
    const modalImage = displayImages.filter((itm) => itm.id === clickedItemId)
    const modal = modalImage.length !== 0 && CreateModal(clickedItemId, setClickedItemId, modalImage[0])

    return [gallery, modal]
}

function getFiltersFromStates (filterStates) {
    let outState = {}
    Object.keys(filterStates).map(
        (key) => outState[key] = filterStates[key].selectedList
    )
    return outState
}

export default function Gallery (props) {
    const { pageNumber } = useParams()
    if (pageNumber === undefined) {
        throw Error("not valid page!")
    }
    const [currentPage, setCurrentPage] = useState(pageNumber || 1)
    const [clickedItemId, setClickedItemId] = useState(null)

    const filterStates = FilterStates()

    useEffect(
        () => setCurrentPage(pageNumber),
        [pageNumber]
    )

    let filters = FilterSet(filterStates);
    let pagination = Paginate(currentPage, setCurrentPage, setClickedItemId, filterStates)
    const [gallery, modal] = GalleryImages(
        currentPage, setCurrentPage, clickedItemId, setClickedItemId, filterStates
    )
    return (
        <div className="gallery-container">
            {modal}
            {filters}
            {gallery}
            {pagination}
        </div>
    )
}
