import React, { useState, useEffect } from "react";
import "../index.css";
import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const BASEURL = process.env.REACT_APP_BASEURL

async function postBirdbattleResult (winningImage, losingImage) {
    const birdUpdate = {
        winner: winningImage,
        loser: losingImage
    }
     axios.post( `${BASEURL}/birdbattle/save`, birdUpdate )
}

async function getNewBattler (exclude_ids, imageSetter) {
    const exclude_ids_str = exclude_ids.map((idx) => `exclude_ids=${idx}`).join("&")
    await axios.get(`${BASEURL}/birdbattle/next?${exclude_ids_str}`)
        .then(res => imageSetter(res.data))
}

function DisplaySingleBattler (image, opponent, setOpponent, votes, setVotes, desktop) {

    function handleClick () {
        const mutate_votes = Object(votes)
        mutate_votes[image.birds_id] = {
            "name": image.alt,
            "votes": parseInt(mutate_votes[image.birds_id]?.votes || 0) + 1
        }

        setVotes(mutate_votes)
        postBirdbattleResult(image, opponent)
        getNewBattler([image.birds_id, opponent.birds_id], setOpponent)
    }

    return (
        <Box>
            <ImageListItem sx={{ margin: "2%" }} key={image.birds_id}>
              <img
                  src={`${image.url}`}
                  srcSet={`${image.url}`}
                  alt={image.alt}
                  key={image.birds_id}
                  loading="lazy"
                  onClick={handleClick}
                  style={{cursor: "pointer"}}
              />
              <ImageListItemBar title={image.alt} />
            </ImageListItem>
            {desktop && <Button variant="contained" sx={{mx: "40%"}} onClick={handleClick}>Vote</Button>}
        </Box>
    )
}

function DisplayBattle (votes, setVotes, desktop) {
    const defaultImage = {
        url: "",
        alt: "",
        birds_id: -1,
        isLoser: false
    }
    const [image1, setImage1] = useState(defaultImage)
    const [image2, setImage2] = useState(defaultImage)

    // initial bird battlers
    useEffect(
        () => {
            axios.get(`${BASEURL}/birdbattle/start`)
                .then(res => {
                    const birds = res.data;
                    setImage1(birds[0]);
                    setImage2(birds[1]);
                })
        },
        []
    )

    return (
        <ImageList sx={{ margin: "3%" }} cols={desktop ? 2 : 1}>
            {DisplaySingleBattler(image1, image2, setImage2, votes, setVotes, desktop)}
            {DisplaySingleBattler(image2, image1, setImage1, votes, setVotes, desktop)}
        </ImageList>
    )
}

function displayVotes (votes) {
    if (!votes) {
        return
    }
    const renderValues = []
    Object.entries(votes).forEach((entry) => {
        renderValues.push((
            <p key={`${entry[0]}`}> {entry[1].name}: {entry[1].votes}</p>
        ))
    })
    return renderValues
}

export default function BirdBattle () {

    const [votes, setVotes] = useState({})
    const desktop = document.documentElement.clientWidth > 500

    return (
        <main>
            <h2 className="centered-text">Choose The Best Bird</h2>
            {!desktop && <p className="centered-text">Click the image to vote</p>}
            {DisplayBattle(votes, setVotes, desktop)}
            <br/>
            <>
                {displayVotes(votes)}
            </>
        </main>
    )
}
