import * as React from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const BASEURL = process.env.REACT_APP_BASEURL


export default function StandardImageList({ listItemsArr, setClickedItemId, hoveredItemId, setHoveredItemId }) {
  const desktop = document.documentElement.clientWidth > 900
  const navigate = useNavigate()
  function handleClick (item) {
    if (desktop) {
      return () => {
        setClickedItemId(item.id)
        axios.post(
            `${BASEURL}/gallery/click`,
            {"click_type": "open", "birds_id": item.id}
        )
      }
    } else {
        return () => {
          navigate(`/profile/${item.id}`);
        };
    }
  }
  function handleHover(id) {
    if (desktop) {
      return () => setHoveredItemId(id)
    }
  }
  return (
    <ImageList sx={{ width: 9/10, margin: "3%"}} variant="masonry" cols={desktop ? 3 : 1}>
      {listItemsArr.map((item) => (
        <ImageListItem sx={{ margin: "2%" }} key={item.id}>
          <img
              src={`${item.url}`}
              srcSet={`${item.url}`}
              alt={item.alt}
              key={item.id}
              loading="lazy"
              onClick={handleClick(item)}
              onMouseOver={handleHover(item.id)}
              onMouseOut={handleHover(null)}
          />
          {(!desktop || hoveredItemId === item.id) && <ImageListItemBar title={item.alt} />}
        </ImageListItem>
      ))}
    </ImageList>
  );
}
