import Box from "@mui/material/Box";


export default function Profile ({ profile }) {
    const desktop = document.documentElement.clientWidth > 500
    return (
        <Box sx={{mx: desktop ? "10%" : "0%"}}>
            <img
                src={`${profile.url}`}
                srcSet={`${profile.url}`}
                alt={profile.alt}
                key={profile.birds_id}
                loading="lazy"
                style={{"maxHeight": "100%", "maxWidth": "100%"}}
            />
            <p className="centered-text"><b>Photo Title:</b> {profile.alt}</p>
            <p className="centered-text"><b>Species Shown:</b> {profile.species?.join(", ")}</p>
            <p className="centered-text"><b>Tags:</b> {profile.tags.length ? profile.tags.join(", ") : "None"}</p>
            <p className="centered-text"><b>Location Taken:</b> {profile.location}</p>
            <p className="centered-text"><b>Date Taken:</b> {profile.photo_date}</p>
        </Box>
    )
}