const aboutData = {
    name: "Jeff",
    paragraphs: [
        "Welcome to the Bird Zone.",
        "This is an early draft of a bad ass new bird site.",
        "Keep an eye out for new features, like the bird leaderboard!",
        "This website is maintained by Jeff Knaide, if you have any questions or comments please send em over to jeffknaide@gmail.com"
    ]
}

export default function getAboutData () {
    const paragraph_renderers = []
    aboutData.paragraphs.forEach((para) => {
        paragraph_renderers.push(
            (<p>{para}</p>)
        )
    })
    return (
        <main>
            {paragraph_renderers}
        </main>
    )
}