import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import NotFound from "./notfound";
import { Profile } from "../components/common";

const BASEURL = process.env.REACT_APP_BASEURL;

function getProfile(birds_id, setProfile, setErr, setIsLoading) {
    axios
        .get(`${BASEURL}/profile/${birds_id}`)
        .then(res => {
            setProfile(res.data)
            setIsLoading(false)
        })
        .catch(err => {
            if (err.response?.status === 404) {
                setErr(true)
            }
        })
}

export default function ProfilePage () {
    const { birds_id } = useParams()
    const [profile, setProfile] = useState({})
    const [err, setErr] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(
        () => getProfile(birds_id, setProfile, setErr, setIsLoading),
        [birds_id]
    )

    if (err) {
        return (<NotFound />)
    } else {
        return isLoading ? (<></>) : (<Profile profile={profile} />)
    }
}