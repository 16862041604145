import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FilterListIcon from "@mui/icons-material/FilterList";
import {useEffect, useState} from "react";
import axios from "axios";

const BASEURL = process.env.REACT_APP_BASEURL

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MultipleSelectChip({label, names, selectedName, setSelectedName, sx = {}}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 300, ...sx }}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId={label}
        id={label}
        multiple
        value={selectedName}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {names.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, selectedName, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FilterStates() {
    const [speciesList, setSpeciesList] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [locationsList, setLocationsList] = useState([])
    const [selectedSpecies, setSelectedSpecies] = React.useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedLocations, setSelectedLocations] = React.useState([]);

    useEffect(
        () => axios.get(`${BASEURL}/gallery/filters`).then(
            resp => {
                setSpeciesList(resp.data.species);
                setTagsList(resp.data.tags);
                setLocationsList(resp.data.locations);
            }
        ), []
    )

    return {
        // Tags: {
        // label: "Tags",
        //     fullList: tagsList,
        //     setList: setTagsList,
        //     selectedList: selectedTags,
        //     setSelectedList: setSelectedTags
        // },
        species: {
            label: "Species",
            fullList: speciesList,
            setList: setSpeciesList,
            selectedList: selectedSpecies,
            setSelectedList: setSelectedSpecies
        },
        locations: {
            label: "Locations",
            fullList: locationsList,
            setList: setLocationsList,
            selectedList: selectedLocations,
            setSelectedList: setSelectedLocations
        }
    }
}


function FilterSet(filterStates) {
    return (
        <Box component="div" sx={{ width: '90%' }} display="flex" alignItems="center">
            <FilterListIcon sx={{ fontsize: "large", width: "3%" }} />
            {
                Object.keys(filterStates).map(
                    (key) => (
                        <MultipleSelectChip
                            label={filterStates[key].label}
                            names={filterStates[key].fullList}
                            selectedName={filterStates[key].selectedList}
                            setSelectedName={filterStates[key].setSelectedList}
                        />
                    )
                )
            }
        </Box>
    )
}

export { MultipleSelectChip, FilterSet, FilterStates };