import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";


import axios from "axios";
import { Navigate } from "react-router-dom";

const BASEURL = process.env.REACT_APP_BASEURL;

export default function ProfileEmail () {
    const { email_id } = useParams();
    const url = `${BASEURL}/emails/${email_id}/profile`
    
    var [targetUrl, setTargetUrl] = useState("")

    useEffect(
        () => axios.get(url).then(
            resp => setTargetUrl(resp.data.target_path)
        ), [setTargetUrl, url]
    )
    if (targetUrl !== "") {
        return (<Navigate to={targetUrl} />)
    } else {
        return (<div></div>)
    }
}