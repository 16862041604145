import axios from "axios";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';

const BASEURL = process.env.REACT_APP_BASEURL


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 1000,
    maxWidth: 2000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CustomModal ({ isOpen, setIsOpen, headingText, modalContent }) {
    const handleClose = () => {
        setIsOpen(null);
        axios.post(
            `${BASEURL}/gallery/click`,
            {"click_type": "close", "birds_id": modalContent.key}
        );
    };
    return (
        <Modal
            open={ isOpen }
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h3" align="center">
                    {headingText}
                </Typography>
                <Container align="center">
                    {modalContent}
                </Container>
            </Box>
        </Modal>
    )
}