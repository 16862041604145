import { useState, useEffect } from "react";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const BASEURL = process.env.REACT_APP_BASEURL

export default function Home() {
    const [birdOfTheWeek, setBirdOfTheWeek] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const desktop = document.documentElement.clientWidth > 900

    useEffect(
        () => {
            axios
                .get(`${BASEURL}/birdOfTheWeek`)
                .then(
                    (res) => {
                        setBirdOfTheWeek(res.data)
                        setIsLoading(false)
                    }
                )
        },
        []
    )

    return !isLoading  && (
        <Grid container alignContent="center">
            <Grid item xs={12}>
                <Typography
                    variant={desktop ? "h2" : "h4"}
                    textAlign="center"
                    sx={{ textShadow: "1px 1px #b8a6b0", color: "black", fontWeight: "bold" }}
                >
                    Bird of the Week!
                </Typography>
                <Typography
                    variant={desktop ? "h5" : "body1"}
                    textAlign="center"
                >
                    {birdOfTheWeek.alt}
                </Typography>
            </Grid>
            <Grid item xs={12} padding={0} textAlign="center">
              <img
                  src={`${birdOfTheWeek.url}`}
                  srcSet={`${birdOfTheWeek.url}`}
                  alt={birdOfTheWeek.alt}
                  key={birdOfTheWeek.id}
                  style={{ maxWidth: "100%", maxHeight: "80%" }}
              />
            </Grid>

        </Grid>
    )
}